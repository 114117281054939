<template>
  <div>
    <text-page>
      <div class="privacy">
        <h1 style="text-align: center">Moss.Chat Privacy Policy</h1>
        <h2 style="text-align: center">Latest Update: 01.01.2022</h2>
        <p>
          <span>moss.chat is an AppstoniA OÜ (“AppstoniA”) service. </span>
        </p>
        <p>
          <span
            >AppstoniA and related websites (moss.chat, appstonia.com,
            shopinix.com), along with mobile applications and other services
            ("Our Services", "Our Websites", "Our Applications") care about your
            privacy.</span
          >
        </p>
        <p>
          <span
            >Therefore, we gather and use your personal data only within the
            extent necessary for providing our Services. The following
            information is included in your personal data:</span
          >
        </p>
        <ul>
          <li>Name</li>
          <li>Address</li>
          <li>Phone number</li>
          <li>E-mail address</li>
          <li>Date of birth</li>
          <li>Other directly or indirectly identifying data.</li>
        </ul>
        <br />
        <p>
          <span
            >Our Privacy Policy aims to; explain how and which data we collect,
            how and why we use your personal data. Furthermore, it also explains
            the options how you can access the control of your processed data,
            update or otherwise control such data.</span
          >
        </p>
        <p>
          <span
            >If you have any questions regarding your rights or our application,
            which are described below, you can always contact our Data
            Protection Officer ("DPO") or the related special team through
            privacy@appstonia.com address. &nbsp;This inbox is actively
            monitored and managed so that we can provide you a secure and good
            experience.
          </span>
        </p>
        <p>&nbsp;</p>
        <h2><span>1. Which information do we collect?</span></h2>
        <p>
          <span
            >We collect information so that we can offer the best possible
            experience when you use our services. Most of the things you
            consider personal data are collected directly from you under the
            following conditions:</span
          >
        </p>
        <ol>
          <li>
            <p>
              <span
                >creating an account or purchasing any one of our Services (e.g.
                name, address, credit card number, official identification
                information and billing data);</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >requesting support from our customer services support team
                (e.g. phone number, e-mail address);
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                >when you fill in the communication forms or request e-bulletin
                or other information from us (e.g. e-mail);</span
              >
            </p>
          </li>
        </ol>
        <p>
          <span
            >However, we also collect additional information while offering our
            Services in order to ensure optimal performance or when necessary.
            &nbsp;Such collection methods may not be clear or understandable for
            you. Therefore, we would like to specify and explain what such
            methods may be (as they are updated from time to time), how they
            work and more:</span
          >
        </p>
        <h3>1.1. Information on accounts</h3>
        <p>
          <span
            >Your purchases, account number, product renewal or expiry, customer
            services requests and notifications on what you requested and how we
            replied are collected in relation to and/or necessary for utilizing
            our Services.</span
          >
        </p>
        <p>
          <span
            >Identifying information and similar technologies in our websites
            and mobile applications allow us to collect various data, including
            your browsing behaviors, clicked links, purchased items, device type
            and how you use our Services as well as analyses on how you
            interact. It is thus possible that we can collect, analyze and
            improve such data in order to provide you with more suitable
            products and a better experience in our mobile applications and that
            performance of our Services is enhanced. We may also collect your
            location information (IP address) so that you can customize our
            Services.
          </span>
        </p>
        <h3>1.2. Data on Use of Services</h3>
        <p>
          <span
            >When you use or interact with our Services; some information,
            including metadata, logs, identifying information, device identities
            and location information, is automatically collected. Such
            information includes the private data regarding your interaction
            with features in the Services, content and links (including third
            parties such as social media add-ons), Internet Protocol (IP)
            address, browser type and setting, the date and hour Services are
            used, information on browser configuration and add-ons, language
            preferences and identifying information data, devices accessing
            Services, device type, the operating system used, device settings,
            application identities, unique device identifiers and error data,
            and some of such collected information may be used to predict your
            approximate location.
          </span>
        </p>
        <p>
          <span
            >We may receive information on you provided by other sources, such
            as public databases or third parties, and combine such information
            with the available information we have on you. This helps us update
            the accuracy of our records, expand and analyze them, identify new
            customers and offer them products and services they might be
            interested. &nbsp;If you provide us personal information of other
            individuals or vice versa, we use such information only for the
            special purposes it is provided to us.</span
          >
        </p>
        <h3>1.3. User Platforms User Information</h3>
        <p>
          <span
            >Furthermore, we may also collect similar information of visitors
            and users of our Users' websites and services ("User Platforms
            Users") solely for and on behalf of our Users (as described in
            detail below).</span
          >
        </p>
        <p>
          <span
            >AppstoniA can collect, store and process some Non-personal and
            Personal Information of User Platforms Users ("User Platforms Users
            Information") solely on behalf and upon instruction of our Users.
            For example, each of our Users can import contacts of a third party
            such as Gmail or otherwise collect and manage contracts through
            their own User Websites. Thereupon, such contracts are stored in
            AppstoniA servers on behalf of the User.</span
          ><span><br class="kix-line-break" /></span
          ><span><br class="kix-line-break" /></span
          ><span
            >In line with this purpose, AppstoniA acts and will be regarded not
            as a "Supervisor" but as a "Processor" (as both capitalized terms
            are defined in the European Union Regulation on General Data
            Protection) for such User Platforms User Information. Users
            supervising and operating such User Websites will be regarded as
            "Supervisor" of such User Platforms User Information and are
            obligated to comply with all the applicable laws and regulations on
            collection and supervision of such User Platforms User Information,
            including all privacy and data protection laws within the concerned
            jurisdiction.</span
          ><span><br class="kix-line-break" /></span
          ><span><br class="kix-line-break" /></span
          ><span
            >Ensuring the security, integrity and authorized use of the
            Information of your User Platforms Users, acquiring the necessary
            approvals and permits for collecting and using such information as
            well as sending all legal information processing notices is under
            your responsibility.</span
          ><span><br class="kix-line-break" /></span
          ><span><br class="kix-line-break" /></span
          ><span
            >AppstoniA does not provide legal consultancy to its Users or User
            Platforms Users; however, we recommend that all Users publish and
            maintain their explicit and extensive privacy policies in their User
            Websites as per the applicable laws and regulations and that all
            User Platforms Users ensure having carefully read and accepted such
            policies.</span
          >
        </p>
        <p>
          <span
            >If you are a Visitor, User or customer of any of your Users, please
            read this paragraph: AppstoniA is not directly related to any of the
            User Platforms Users, information of whom is processed by AppstoniA.
            If you a Visitor, User or customer of any of our Users, when you
            want to make a request or ask a question regarding your Personal
            Information, please contact directly with such User/Users. For
            example, If you want to access to wrong information processed by
            AppstoniA on behalf of Users, correct, change or delete such
            information, please direct your request to the concerned User
            ("Supervisor" of such data). If a request is made for removing any
            Personal Information belonging to User Platforms Users, we will
            reply such request within thirty (30) days. Unless we are otherwise
            instructed by our User, we will store the User Platforms Personal
            Information of such User for the period of time stated below.</span
          >
        </p>
        <a name="cookies"></a>
        <p>&nbsp;</p>
        <h3>1.4. Cookies Information</h3>
        <p>
          <span
            >Cookies is a small unit of text sent to your web browser by the
            visited website. This helps the website remember the information
            regarding your visit (e.g. your preferred language, creating a cart
            for purchases or other settings). That is to say that cookies may
            provide convenience in your current or future visits and make the
            website more practical. Cookies play an important role for Internet.
            Without cookies, the use of Web would be harder.</span
          ><span><br class="kix-line-break" /></span
          ><span><br class="kix-line-break" /></span
          ><span
            >You can find a list of cookies used by Appstonia below. How we
            protect your privacy while using cookies and other information is
            also explained in our privacy policy.</span
          >
        </p>
        <h4>1.4.1. Cookies In Terms of Retention Period</h4>
        <h5>1. Persistent Cookies:</h5>
        <p>
          <span>
            Cookies exist in the individual's computer until a certain date or
            until deleted by the user. Such cookies are mostly used to measure
            website behaviors and preferences of users.</span
          >
        </p>
        <h5><span>2. Session Cookies:</span></h5>
        <p>
          <span>
            These cookies are used to separate users' visit into sessions and
            does not collect data from the user. Such cookies are deleted once
            the user closes the visited website or remains passive for a while. </span
          ><span
            ><br class="kix-line-break" /><br class="kix-line-break"
          /></span>
        </p>
        <h4>1.4.2. Types of Cookies In Terms of Uses</h4>
        <div>
          <table class="cookie-types">
            <colgroup>
              <col width="180" />
              <col width="500" />
            </colgroup>
            <tbody>
              <tr>
                <td><h4>Category of use</h4></td>
                <td><h4>Description and Example</h4></td>
              </tr>
              <tr>
                <td>
                  <p></p>
                  <h5>Preferences</h5>
                  <p></p>
                </td>
                <td>
                  <p>
                    <span
                      >These cookies ensure that websites remember the
                      information changing the operation and view method. For
                      example, a website may remember your location, thus
                      remembering the language and currency of your location.
                      Such cookies may also help you change the text size, font
                      and other elements you can customize in websites.</span
                    >
                  </p>
                  <p>
                    <span
                      >Losing the information stored in a preference cookie may
                      make the Web experience less functional, but should not
                      hinder the operation.</span
                    >
                  </p>
                  <p>
                    <span
                      >Example: "sidebarstate" cookie stores the information if
                      the left menu is open when you log in, and thus, ensures
                      that the website runs in the same way every time. Or the
                      "cookie_law" cookie remembers your confirmation of the
                      "Privacy and General Terms of Use" upon your visit on the
                      Website and ensures that such agreement is not resubmitted
                      in your next visit.</span
                    >
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p></p>
                  <h5>Mandatory</h5>
                  <p></p>
                </td>
                <td>
                  <p>
                    <span
                      >Mandatory cookies help in ensuring that our Services work
                      and in providing the services expected from the website,
                      such as surfing on Web pages or accessing secure areas of
                      the website. Without these cookies, the website will not
                      work properly.</span
                    >
                  </p>
                  <p>
                    <span
                      >For example, users must sign in for using our Services
                      and managing the User Platform. Signing in and providing
                      the user-specific content depend on the use of such
                      cookies and a successful sign in cannot be performed
                      unless these cookies are accepted.</span
                    >
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p></p>
                  <h5>Analytics</h5>
                  <p></p>
                </td>
                <td>
                  <p>
                    <span
                      >We use Google Analytics on our Websites. Google Analytics
                      is the free analysis tool of Google that helps us
                      understand what visitors do on or Websites and
                      applications. It may use a series of cookies for gathering
                      information and reporting the website use statistics to
                      Google without identifying the visitors. The main cookie
                      used by Google Analytics is &nbsp;‘__ga’.
                    </span>
                  </p>
                  <p>
                    <span>Obtain more information on </span
                    ><a
                      href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
                      ><span>Analytics cookies and privacy information</span></a
                    ><span> through the following link.</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>&nbsp;</p>
        <h1><span>2. Why do we collect this information?</span></h1>
        <p>
          <span
            >We collect such Non-personal and Personal Information for the
            following purposes:</span
          >
        </p>
        <ol>
          <li>
            <span>Providing and operating the Services</span>
            <p></p>
          </li>
          <li>
            <span
              >Further enhancing, customizing and improving our Services during
              common or personal preferences, experiences and problems of
              Visitors and Users</span
            >
            <p></p>
          </li>
          <li>
            <span
              >Providing continuous customer and technical support to our
              Users.</span
            >
            <p></p>
          </li>
          <li>
            <span
              >Contacting our Visitors and Users with general or customized
              notifications and promotion messages regarding the Services</span
            >
            <p></p>
          </li>
          <li>
            <span
              >Enabling and supporting certain contests, activities and
              promotions and presenting them, determining conformity of
              participants, monitoring their performances, contacting the
              winners and granting rewards and rights</span
            >
            <p></p>
          </li>
          <li>
            <span
              >Generating the aggregate statistical data and other aggregate
              and/or extracted Non-personal Information that can be used for
              providing and improving our or our partners' related
              services</span
            >
            <p></p>
          </li>
          <li>
            <span
              >Increasing our data security and forgery prevention
              capabilities</span
            >
            <p></p>
          </li>
          <li>
            <span>Evaluating candidacy of AppstoniA Job Applicants </span>
            <p></p>
          </li>
          <li>
            <span>Conforming with all applicable laws and regulations</span>
            <p></p>
          </li>
        </ol>
        <p>
          <span
            >We will use your Personal Information solely for the purposes
            stated in this Article, provided that we are satisfied with the
            following matters:</span
          >
        </p>
        <p><span> </span></p>
        <ol>
          <li>
            <p>
              <span
                >If using your Personal Information is necessary for performing
                a contract between us or carrying out the necessary processes
                for executing a contract (for example, for providing you a
                website creator, customer or technical support) or</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >If using your Personal Information is necessary in order to
                comply with an obligation as per any applicable law or
                regulation, or</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >If using your Personal Information is necessary for supporting
                our legitimate interests as a company (for example, for
                maintaining and improving the activity of our Services,
                AppstoniA campaigns by defining user trends and for identifying
                technical problems),
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <span>2.1. Providing, Updating and Improving Offered Services</span>
        </h3>
        <p>
          <span
            >We collect various information regarding your purchasing behaviors,
            uses and/or interactions in relation to our Services. We do the
            followings by collecting such information:</span
          >
        </p>
        <ol>
          <li>
            <p>
              <span
                >Improving and optimizing the operation of our Services
                (including or websites and mobile applications)</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >Determining problems regarding the Services and identifying any
                security risks, errors or necessary improvements</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >Detecting and preventing fraud and misuse in our Services and
                systems</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>Gathering statistics regarding the use of Services</span>
            </p>
          </li>
          <li>
            <p>
              <span
                >Understanding and analyzing how you use our Services and which
                products and services are most suitable to you.</span
              >
            </p>
          </li>
        </ol>
        <br />
        <p>
          <span
            >Usually, most of the gathered data is bulk or statistical data
            regarding how individuals use our Services and no personal data is
            related; however, we put such data into process within the extent of
            personal data or relation to personal data.</span
          >
        </p>
        <h3><span>2.2. Disclosure to Reliable Third Parties</span></h3>
        <p>
          <span
            >We may disclose your personal data to our affiliates, cooperated
            third parties, third parties in order to allow for integration of
            such third parties' services to our own Services, and to reliable
            third-party service providers in order that such third parties
            provide their services on our behalf, for example:</span
          >
        </p>
        <ol>
          <li>
            <p><span>Accepting credit card payments</span></p>
          </li>
          <li>
            <p><span>Ad serving</span></p>
          </li>
          <li>
            <p><span>Conduction contests or surveys</span></p>
          </li>
          <li>
            <p>
              <span
                >Analysis of demographic information of our Services and
                customers</span
              >
            </p>
          </li>
          <li>
            <p><span>Contacting you through e-mail or questionnaires</span></p>
          </li>
          <li>
            <p><span>Customer relationships management.</span></p>
          </li>
        </ol>
        <br />
        <p>
          <span
            >We disclose your personal data within the form and extent necessary
            for third parties to prevent services on our behalf. Such third
            parties (or any subcontractors) are subject to strict data
            processing terms and conditions and are prohibited from using,
            disclosing or storing your personal data for other purposes without
            a contract (or without your approval).</span
          >
        </p>
        <h3><span>2.3. Contacting you</span></h3>
        <p>
          <span
            >We may contact you directly or through a third-party service
            provider for establishing the necessary communication regarding a
            process or service in relation to products or services you purchase
            from us. Additionally, we may also contact you with our offers that
            you might find valuable upon your approval or an approval based on
            legal interests. Your approval is not stipulated for purchasing our
            products or services.</span
          ><span><br /></span
          ><span> Such communications may include the following:</span>
        </p>
        <ol>
          <li>
            <p><span>E-mail</span></p>
          </li>
          <li>
            <p><span>Short (SMS) messages</span></p>
          </li>
          <li>
            <p><span>Phone calls</span></p>
          </li>
          <li>
            <p><span>Automatic phone calls or short messages.</span></p>
          </li>
        </ol>
        <br />
        <p>
          <span
            >You can also update your subscription preferences for receiving
            communications by us and/or our partners through signing in your
            account and visiting the "My Info" page.
          </span>
        </p>
        <p>
          <span
            >If we obtain information from you regarding a co-brand offer, whom
            the information will be provided and privacy policy of which partner
            will be applicable will be explicitly specified when we obtain such
            information. Additionally, options regarding the use and/or
            disclosure of your personal data with a co-branded partner as well
            as how such options are used will also be explained.</span
          >
        </p>
        <p>
          <span
            >If you utilize a service allowing you to import contacts (e.g.
            using e-mail marketing services for sending e-mails on your behalf),
            we use the contacts and other personal information only for the
            demanded service. If you think that your information has been
            provided to us by any parties and would like that such information
            is removed from our database, please contact us via
            privacy@appstonia.com address.</span
          >
        </p>
        <h3><span>2.4. Transferring Personal Data Abroad</span></h3>
        <p>
          <span
            >If you use our Services in a country other than those our servers
            are located in, your communications with us may result in transfer
            of your data beyond international boundaries. Furthermore, when you
            call us or initiate a chat, we may provide support through one of
            our global locations outside your country. In such cases, your
            personal data are handled according to this Privacy Policy.</span
          >
        </p>
        <h3>
          <span
            >2.5. Conformity to legal, regulatory and enforcement
            requirements</span
          >
        </h3>
        <p>
          <span
            >We work cooperatively with governmental agencies, the police and
            private corporations in order to ensure conformity to laws and
            regulations. We, at our own discretion, will disclose your
            information to governmental agencies, the police or private
            corporations in order to reply to any claims or legal proceedings
            (such as subpoenas) or when necessary, in order to protect our
            properties or rights or those of third parties, when we are of the
            opinion that security of a community or individual will be protected
            or that an illegal or unethical activity will be prevented or
            stopped.</span
          >
        </p>
        <p>
          <span
            >In case that we are required to provide your personal information
            to a third party to a legal proceeding, we inform you to take
            reasonable steps within this regard, within the extend that we are
            legally allowed to do so. When you register a domain name with us,
            we will disclose your information within the necessary extent in
            order to comply with the rules, regulation or policies of ICANN or a
            ccTLD.</span
          >
        </p>
        <h3><span>2.6. Analytics of Our Websites </span></h3>
        <p>
          <span
            >We use web analysis tools provided by service partners, such as
            Google Analytics, in order to gather information on how you interact
            with our website or mobile applications, which pages you visit,
            which website you visit before visiting our website, the time you
            spend on each page, your operating system and web browser, web and
            IP information. We use the information provided by such tools to
            improve our Services. These tools places permanent identifying
            information to your browser so that you are defined as a unique user
            the next time you visit our website. Identifying information is not
            used by any party other than the service provider (e.g. Google for
            Google Analytics). Information received from identifying information
            can be transferred to and stored in servers outside your country.
            Even when the gathered information does not include personal data
            such as name, address, billing information, etc., such information
            is used and disclosed by the service provider in accordance with the
            personal privacy policies. You can control our 'identifying
            information ads', which can be provided through using third party
            tools or the settings on your browser at your first visit on our Web
            pages (based on the URL of the visited web page), and the
            technologies we use by managing your settings.</span
          >
        </p>
        <h3><span>2.7. Target Advertisements</span></h3>
        <p>
          <span
            >Target advertisements and offers based on interests can be
            presented to you based on your activities on our web pages and other
            websites as well as your currently owned products. &nbsp;Such offers
            will be shown as various product advertisements offered to you as
            you surf on the Internet. We also establish partnerships with third
            parties (e.g. Google Ads) to manage our advertisements, web pages
            and other websites. Our third party partners may use technologies
            such as identifying information for gathering information on your
            activities in order to provide advertisements based on your browsing
            activities and interests and measure advertisement effectiveness.
            &nbsp;</span
          >
        </p>
        <p>
          <span
            >If you would like to disable advertising based on interests [or if
            you are resident in the European Union], contract us through the
            "Contact" section. Please remember that you will continue receiving
            general advertisements.</span
          >
        </p>
        <h3><span>2.8. Third Party Websites</span></h3>
        <p>
          <span
            >Our Websites and mobile applications contain links to third
            parties. We are nor responsible for privacy applications or contents
            of third party websites. Please read the privacy policies of the
            websites you visit.</span
          >
        </p>
        <p>&nbsp;</p>
        <h2><span>3. How to access, update or delete your data?</span></h2>
        <p>
          <span
            >Please Sign In and visit the "Edit my User Information" page in
            order to easily access, view, update, delete or transfer your
            personal data (if there is any) or update your subscription
            preferences. Contact us through the "Contact" section in order to
            receive additional information and support on accessing, updating
            and deleting data.
          </span>
        </p>
        <p>
          <span
            >If you make a request for deleting your personal data and such data
            is necessary for the products or services you purchased, your
            request will be carried out only if such data is not necessary for
            the purchased Services or no longer needed for our legal business
            goals or not included within the scope of contractual recording
            requirements.</span
          >
        </p>
        <p>
          <span
            >If you have any problems accessing your Account Settings or our
            Privacy Centre, you can contact us through one of the methods
            described in the "Contact" section below.
          </span>
        </p>
        <p>&nbsp;</p>
        <h2><span>4. How do we protect and store your data?</span></h2>
        <p>
          <span
            >We apply the generally accepted standards, including the use of
            proper encryption, both during and after the transfer in order to
            store and protect the gathered personal data.</span
          >
        </p>
        <p>
          <span
            >We store personal data only for the period necessary for providing
            the requested Services and, after such period, solely for several
            legal or commercial purposes. These may include the following
            retaining periods:</span
          >
        </p>
        <ol>
          <li>
            <p>
              <span
                >as necessitated by laws, contracts or similar obligations
                applicable for our business activities;</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >for protecting, solving, defending or enjoying our
                legal/contractual rights; or</span
              >
            </p>
          </li>
          <li>
            <p>
              <span
                >maintaining the adequate and correct business and financial
                records.</span
              >
            </p>
          </li>
        </ol>
        <br />
        <p>
          <span
            >If you have any questions regarding the security or protection of
            your personal data, you can contact us through privacy@appstonia.com
            address.</span
          >
        </p>
        <h3><span>4.1. ‘Do Not Track’ notifications.</span></h3>
        <p>
          <span
            >Some browsers allow you to notify websites not to track you using
            the "Do Not Track" mark. Within this context, sector participants
            have not reached a general opinion regarding what the "Do Not Track"
            mark means. As in many other websites and online services, we do not
            change our applications when we receive the "Do Not Track" mark from
            a visitor's browser. For more information on “Do Not Track,” you can
            visit </span
          ><a href="http://www.allaboutdnt.com/"
            ><span>www.allaboutdnt.com</span></a
          ><span> address.</span>
        </p>
        <p>&nbsp;</p>
        <h2><span>5. Age restrictions.</span></h2>
        <p>
          <span
            >Our services can only be purchased for individuals aged 18 or over.
            &nbsp;Our Services are not designed or aimed to allure or use
            minors. Please contact us if you have any reasons to think that
            individuals below 18 provide us with any personal data.</span
          >
        </p>
        <p>&nbsp;</p>
        <h2><span>6. Changes in our Privacy Policy.</span></h2>
        <p>
          <span
            >Our right to make changes to this Privacy Policy is reserved. If we
            decide to change our Privacy Policy, we notify you the changes we
            made in this Privacy Policy and where deemed suitable, thus ensuring
            that you know which information we gather, how and when we use such
            information and which information we disclose, if we disclose any.
            If we make significant changes to this Privacy Policy, we inform you
            of such privacy policy page through e-mail or with an announcement
            on our home page at least thirty (30) days prior to implementation
            of such change.</span
          >
        </p>
        <p>&nbsp;</p>
        <h2><span>7. Data protection authority.</span></h2>
        <p>
          <span
            >If you are resident within the European Economic Area (EEA) and
            believe that your data is subject to General Data Protection
            Regulation (GDPR), you can direct your questions or complaints to
            United Kingdom Information Commission (https://ico.org.uk), which is
            the related main supervisory authority.</span
          ><span>.</span>
        </p>
        <p><span>Information Commission</span></p>
        <p>
          <span
            >Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, United
            Kingdom</span
          ><span><br class="kix-line-break" /></span
          ><span>Phone: 0303 123 1113</span>
        </p>
        <p>&nbsp;</p>
        <h2><span>8. Contact Us</span></h2>
        <p>
          <span
            >If you have any questions, concerns or complaints regarding our
            Privacy Policy, applications or services, you can contact our DPO
            department through privacy@appstonia.com address.
            &nbsp;Additionally, you can contact us through one of the following
            methods:</span
          >
        </p>
        <ul>
          <li>
            <span>Via E-mail: </span
            ><a href="mailto:privacy@appstonia.com"
              ><span>privacy@appstonia.com</span></a
            >
          </li>
          <li><span>Via Phone: +372 618 8049</span></li>
          <li><span>Via mail: Sepapaja 6, Tallinn 15551,Estonia</span></li>
        </ul>
        <br />
        <p>
          <span
            >We will reply all your requests, questions or concerns within
            thirty (30) days.</span
          >
        </p>
      </div>
    </text-page>
  </div>
</template>

<script>
import TextPage from '../components/TextPage.vue'

export default {
  components: {
    TextPage,
  },
}
</script>

<style lang="scss" scoped></style>
